import { lazy } from 'react';


import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';


// sample page routing
const ServiceView = Loadable(lazy(() => import('views/pages/reportes/ServiceView')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
                <MinimalLayout />
    ),
    children: [
        {
            path: '/reportes/:id',
            element: <ServiceView />
        },
    ]
};

export default MainRoutes;
